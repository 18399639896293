import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueThailandAddress from "vue-thailand-address";
import "vue-thailand-address/dist/vue-thailand-address.css";
import VJsf from "@koumoul/vjsf/lib/VJsf.js";
import "@koumoul/vjsf/dist/main.css";
import VueQuillEditor from "@vueup/vue-quill/dist/vue-quill.snow.css";
import VueQrcodeReader from "vue-qrcode-reader";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import Croppa from "vue-croppa";
import 'leaflet/dist/leaflet.css';

Vue.use(Croppa);

Vue.use(VuePlyr);
Vue.use(VueQrcodeReader);
Vue.component("VJsf", VJsf);

Vue.use(VueThailandAddress);
Vue.use(VueSkycons, {
  color: "#563dea",
});
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
Vue.use(Vuebar);

Vue.use(VueQuillEditor);
new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
